<template>
  <div
    v-if="userDetailsLoader"
    class="flex items-center justify-center w-full py-24 h-52 sm:py-28 md:py-36 lg:py-52"
  >
    <base-svg
      class="h-4 w-4 mr-1 text-primary-red inline-block"
      src="icons/circleSpinner.svg"
      tag="span"
    />
    Loading ...
  </div>
  <template v-else>
    <div class="mb-6">
      <span class="font-semibold">Username:</span> {{ userPermissionsForm.username }}
    </div>
    <collapse-card
      :model-value="true"
    >
      <template #title>
        <span class="mr-2 text-sm font-medium">
          Properties
        </span>
      </template>
      <template #cardContent>
        <div class="py-2 px-4">
          <div class="grid grid-cols-2 gap-x-10 gap-y-4">
            <base-input
              v-model="userPermissionsForm.firstName"
              type="text"
              container-class="w-full flex items-center"
              label-class="label-w-46"
              label="First Name"
              disabled
            />
            <base-input
              v-model="userPermissionsForm.lastName"
              type="text"
              container-class="w-full flex items-center"
              label-class="label-w-46"
              label="Last Name"
              disabled
            />
            <base-input
              v-model="userPermissionsForm.username"
              type="text"
              container-class="w-full flex items-center"
              label-class="label-w-46"
              label="Login Name"
              disabled
            />
            <base-select
              v-if="!userPermissionsForm.impersonate"
              v-model="userPermissionsForm.roles"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Roles"
              label-class="label-w-46"
              multi-select
              show-default-option
              item-text-property="rolename"
              item-value-property="roleId"
              :options="roleOptions"
              @dropdownOpened="fetchRoles()"
            />
            <!-- <base-select
              v-model="userPermissionsForm.priveleges"
              class="h-7 flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Priveleges"
              label-class="label-w-46"
              :options="privelegesOptions"
              show-default-option
              @dropdownOpened="fetchPriveleges()"
            /> -->
            <!-- <base-select
              v-model="userPermissionsForm.preference"
              class="h-7 flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Apply Preference"
              label-class="label-w-46"
              :options="preferenceOptions"
              show-default-option
              @dropdownOpened="fetchPreference()"
            /> -->
            <base-input
              v-model="userPermissionsForm.email"
              type="text"
              container-class="w-full h-7 flex items-center"
              label-class="label-w-46"
              label="Email ID"
              disabled
            />
            <div class="w-full flex items-center">
              <div
                v-if="!userPermissionsForm.impersonate"
                class="text-sm text-black mr-3.5 w-46 flex-shrink-0"
              >
                Access
              </div>
              <!-- <div
                v-if="userPermissionsForm.impersonate"
                class="flex items-center"
              >
                <span class="font-semibold">{{ userPermissionsForm.access === 'N'?'Disabled':'Enabled' }}</span>
              </div> -->
              <div
                v-if="!userPermissionsForm.impersonate"
                class="flex items-center"
              >
                <base-radio
                  v-model="userPermissionsForm.access"
                  name="access"
                  label="Enabled"
                  value="Y"
                  :checked="userPermissionsForm.access === 'Y'"
                />
                <base-radio
                  v-model="userPermissionsForm.access"
                  name="access"
                  label="Disabled"
                  value="N"
                  class="ml-7"
                  :checked="userPermissionsForm.access === 'N'"
                />
              </div>
            </div>
          </div>

          <div><span>&nbsp;</span></div>
          <div class="grid grid-cols-2 gap-9">
            <div class="w-full flex items-center">
              <div
                v-if="userPermissionsForm.impersonate"
                class="text-sm text-black mr-3.5 w-46 flex-shrink-0"
              >
                Access
              </div>
              <div
                v-if="userPermissionsForm.impersonate"
                class="flex items-center"
              >
                <span class="font-semibold">{{ userPermissionsForm.access === 'N'?'Disabled':'Enabled' }}</span>
              </div>
            </div>
            <div class="w-full flex items-center">
              <div
                v-if="userPermissionsForm.impersonate"
                class="text-sm text-black mr-3.5 w-46 flex-shrink-0"
              >
                Allowed without Licensee
              </div>
              <div
                v-if="userPermissionsForm.impersonate"
                class="flex items-center"
              >
                <span class="font-semibold">{{ userPermissionsForm.allowedWithoutLic === 'Y'?'Yes':'No' }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="userPermissionsForm.impersonate"
            class="w-full flex items-center"> <span>&nbsp;</span>
          </div>
          <div class="grid grid-cols-2 gap-9">
            <div
              v-if="userPermissionsForm.impersonate"
              class="w-full flex items-center"
            >
              <div class="text-sm text-black mr-3.5 w-46 flex-shrink-0">
                Role(s)
              </div>
              <div
                class="flex items-center"
              >
                <ul v-if="userPermissionsForm?.roleNames?.length">
                  <li v-for="role in userPermissionsForm?.roleNames">
                    {{ role }}
                  </li>
                </ul>
                <ul v-else>
                  <li> None</li>
                </ul>
              </div>
            </div>
            <div
              v-if="userPermissionsForm.impersonate"
              class="w-full flex items-center"
            >
              <div class="text-sm text-black mr-3.5 w-46 flex-shrink-0">
                Group(s)
              </div>
              <div
                class="flex items-center"
              >
                <ul v-if="userPermissionsForm?.groups?.length">
                  <li v-for="group in userPermissionsForm?.groups">
                    {{ group }}
                  </li>
                </ul>
                <ul v-else>
                  <li> None</li>
                </ul>
              </div>
            </div>
            <div
              v-if="userPermissionsForm.impersonate"
              class="w-full flex items-center"
            >
              <div class="text-sm text-black mr-3.5 w-46 flex-shrink-0">
                Licensee(s)
              </div>
              <div
                class="flex items-center"
              >
                <ul v-if="userPermissionsForm?.licensees?.length">
                  <li v-for="licensee in userPermissionsForm?.licensees">
                     {{ licensee.licenseeName }}  ({{ licensee.licenseeId }})
                  </li>
                </ul>
                <ul v-else>
                  <li> None</li>
                </ul>
              </div>
            </div>
          </div>
          <div><span>&nbsp;</span></div>
        </div>
      </template>
    </collapse-card>
    <div class="flex justify-end my-6">
      <base-button
        variant="btn-primary"
        class="mr-3"
        :disabled="!userPermissionsForm.email || currentUser.email?.toLowerCase() === userPermissionsForm.email?.toLowerCase()"
        :text="userPermissionsForm.impersonate?'Assume User':'Save'"
        @click="editUser()"
      />
      <base-button
        variant="btn-link"
        text="Discard"
        @click="handleCancel()"
      />
    </div>
  </template>
</template>

<script>
import { defineAsyncComponent, ref, onMounted, computed } from 'vue';
import useUserPermissionsForm from './userPermissionsForm.js';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, SUCCESS, USER_DETAILS_UPDATE_SUCCESS, USER_DETAILS_UPDATE_FAILED } from '@/constants/alerts.js';
import { impersonateUserService } from '@/api/requests/usersRequests.js';
import * as PERMISSIONS from '@/constants/permissions.js';
import * as BA_ROLES from '@/constants/ba-roles.js';

export default {
    name: 'Properties',

    components: {
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseRadio: defineAsyncComponent(() => import('@/components/generic-components/BaseRadio.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    emits: [
        'discard'
    ],

    setup (props, { emit }) {
        const store = useStore();
        const router = useRouter();
        const { userPermissionsForm, isUserPermissionFormUpdated } = useUserPermissionsForm();
        const { notificationsStack } = useToastNotifications();
        const currentUser = computed(() => store.getters['auth/getUserInfo']);

        onMounted(async () => {
            await fetchRoles();
            fetchUserDetails();
        });

        const userDetailsLoader = ref(true);
        const fetchedUserDetails = ref({}); // computed(() => store.getters['users/getUserDetailsList']);
        const userDetailsLoading = ref(false);
        const fetchUserDetails = async () => {
            try {
                if (userDetailsLoading.value) {
                    return;
                }
                userDetailsLoading.value = true;
                userDetailsLoader.value = true;
                fetchedUserDetails.value = await store.dispatch('users/fetchUserDetailsByUserName', {
                    params: {
                        username: userPermissionsForm.value.username
                    }
                });
                // console.log(`fetchedUserDetails.value...${JSON.stringify(fetchedUserDetails.value)}`);
                userPermissionsForm.value.username = fetchedUserDetails.value.username;
                userPermissionsForm.value.firstName = fetchedUserDetails.value.firstname;
                userPermissionsForm.value.lastName = fetchedUserDetails.value.lastname;
                userPermissionsForm.value.email = fetchedUserDetails.value.email;
                userPermissionsForm.value.roles = fetchedUserDetails.value.roles?.length ? fetchedUserRoles.value.filter(role => fetchedUserDetails.value.roles.includes(role.rolename)).map(role => role.roleId) : [];
                userPermissionsForm.value.roleNames = fetchedUserDetails.value.roles;
                userPermissionsForm.value.access = fetchedUserDetails.value.status;
                userPermissionsForm.value.groups = fetchedUserDetails.value.groups;
                userPermissionsForm.value.licensees = fetchedUserDetails.value.licensees;
                userPermissionsForm.value.allowedWithoutLic = fetchedUserDetails.value.allowedWithoutLicId;
            } catch (err) {
                console.error(err);
            } finally {
                userDetailsLoading.value = false;
                userDetailsLoader.value = false;
            }
        };

        const roleOptions = computed(() => {
            const filteredRolesOpt = fetchedUserRoles?.value?.filter(role => role?.roleId !== 'BAC_FINAL');
            return filteredRolesOpt || [];
        });

        const fetchedUserRoles = computed(() => store.getters['users/getAllRolesList']);
        const roleOptionsLoading = ref(false);
        const fetchRoles = async () => {
            try {
                if ((fetchedUserRoles.value && fetchedUserRoles.value.length > 0) || roleOptionsLoading.value) {
                    return;
                }
                roleOptionsLoading.value = true;
                await store.dispatch('users/fetchAllRoles');
            } catch (err) {
                console.error(err);
            } finally {
                roleOptionsLoading.value = false;
            }
        };

        const privelegesOptions = ref([
            'options 1',
            'options 2',
            'options 3',
            'options 4'
        ]);
        const preferenceOptions = ref([
            'options 1',
            'options 2',
            'options 3',
            'options 4'
        ]);
        const fetchPriveleges = () => {};
        const fetchPreference = () => {};

        // form actions logic
        const isUserDetailUpdating = ref(false);
        const editUser = async () => {
            const params = {};
            // console.log(`impersonate...${impersonate}`);
            if (isUserDetailUpdating.value) {
                return;
            }
            try {
                isUserDetailUpdating.value = true;
                if (userPermissionsForm.value.impersonate) {
                    params.assumeUser = userPermissionsForm.value?.username;
                    const result = await impersonateUserService(params);
                    // console.log(`result.data...${JSON.stringify(result.data.data)}`);
                    fetchedUserDetails.value.name = result.data.data.firstname + ', ' + result.data.data.lastname;
                    fetchedUserDetails.value.preferred_username = result.data.data.username;
                    //  commit('setStyleGuideDirectory', data);
                    // const username = userPermissionsForm.value.username;
                    store.commit('auth/setUserProxyInfo', fetchedUserDetails.value);
                    const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);
                    await store.dispatch('assetsSearch/resetAssetsSearch', null, { root: true });
                    await store.dispatch('assets/resetAssets', null, { root: true });
                    await store.dispatch('assetsSearch/clearSavedSearches', null, { root: true });
                    await store.dispatch('activeDownloads/clearDownloadsList', null, { root: true });
                    await store.dispatch('basket/clearBasketCache', null, { root: true });
                    await store.dispatch('styleGuideTree/resetSelectedNodeStack', null, { root: true });
                    await store.dispatch('styleGuideTree/resetStyleGuideTree', null, { root: true });
                    if (proxyUser?.value?.licensees?.length > 1) {
                        router.replace({ name: 'LicenseeSelection' });
                    } else if (proxyUser?.value?.licensees?.length === 1) {
                        store.commit('auth/setSelectedLicensee', proxyUser?.value?.licensees[0]);
                        if (!proxyUser.value?.roles?.includes('Style Guide Access')) {
                            await router.push({ path: 'inbox' });
                            location.reload();
                        } else {
                            router.replace({ name: 'PortalSelection' });
                            location.reload();
                        }
                    }

                } else {
                    await store.dispatch('users/updateUserRoles', {
                        bodyPayLoad: {
                            username: userPermissionsForm.value.username,
                            roleIds: userPermissionsForm.value.roles,
                            status: userPermissionsForm.value.access
                        }
                    });
                }
                isUserPermissionFormUpdated.value = true;
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: USER_DETAILS_UPDATE_SUCCESS
                });
                handleCancel();
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: userPermissionsForm.value.roles.length === 0 ? 'Minimum one role is required' : USER_DETAILS_UPDATE_FAILED
                });
            } finally {
                isUserDetailUpdating.value = false;
            }
        };

        const handleCancel = () => {
            emit('discard');
        };

        const redirectReload = async () => {
            await router.push({ path: 'BAInbox' })
            router.go(0);
        }

        return {
            userPermissionsForm,
            roleOptions,
            editUser,
            handleCancel,
            privelegesOptions,
            preferenceOptions,
            userDetailsLoader,
            fetchRoles,
            fetchPriveleges,
            fetchPreference,
            currentUser
        };
    }
};
</script>
